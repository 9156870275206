@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply overflow-x-hidden;
    }
}

@layer components {
    .button {
        @apply border border-none rounded-lg inline-block py-5 px-6 text-base font-bold bg-gradient-to-r from-[#13A9FD] to-[#00D1FF] text-white;
    }

    .input {
        @apply !w-full border border-[#0566CD] text-base my-2 px-4 py-5 rounded-lg;
    }
}